import moment from 'moment';

const callMaybe = function (context: any, functionOrValue: any) {
  if (typeof functionOrValue === 'function') {
    return functionOrValue.call(context);
  } else {
    return functionOrValue;
  }
};

export const makeYearAwareCalendar = function (fmt: any) {
  fmt = { ...fmt };
  const { sameYear, sameElse } = fmt;
  delete fmt.sameYear;
  fmt.sameElse = function () {
    if (this.isSame(moment(), 'year')) {
      return callMaybe(this, sameYear);
    } else {
      return callMaybe(this, sameElse);
    }
  };
  return fmt;
};
